import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";

import t from "../locale";

const Post = ({ pageContext }) => {
  const {
    title,
    content,
    date,
    image,
    next,
    prev,
    categories,
    language,
    postTranslations,
  } = pageContext;

  const dateFormat = new Date(date);

  const months = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ];

  const year = dateFormat.getFullYear(),
    monthIndex = dateFormat.getMonth(),
    monthName = months[monthIndex],
    day = dateFormat.getDate();
  return (
    <Layout
      seoTitle={title}
      lang={language}
      postTranslations={postTranslations}
      translationEN=""
      translationDE=""
    >
      <SmallBanner
        title={title}
        aditionalBreadcrump={t("Aktualności", language)}
        aditionalBreadcrumpLink={t("/aktualnosci/", language)}
        smallTitle={true}
      />
      <section className="single-news">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5 col-xxl-4">
              <div className="single-news__info-box">
                <div
                  className="info-box__image"
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                ></div>
                <div className="info-box__info">
                  <div className="info__item">
                    <img
                      src={require("../assets/img/calendar.svg")}
                      alt="Kalendarz"
                    />
                    <div className="item__content">
                      <p className="small">{t("Data", language)}</p>
                      <p className="bold">{`${day} ${monthName} ${year}`}</p>
                    </div>
                  </div>
                  <div className="info__item">
                    <img
                      src={require("../assets/img/category-icon.svg")}
                      alt="Kategoria"
                    />
                    <div className="item__content">
                      <p className="small">{t("Kategoria", language)}</p>
                      <p className="bold">
                        {categories.nodes.map((item) => item.name)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-7 col-xxl-8">
              <div
                className="single-news__content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <div
                className={`single-news__nav ${
                  !prev ? "single-news__nav--next" : ""
                }`}
              >
                {prev ? (
                  <Link to={prev.path} className="nav__item nav__item--prev">
                    <p className="nav__text xs">{t("Poprzedni", language)}</p>
                    <h3 className="nav__text">{prev.title}</h3>
                  </Link>
                ) : (
                  ""
                )}
                {next ? (
                  <Link to={next.path} className="nav__item nav__item--next">
                    <p className="xs nav__text">{t("Następny", language)}</p>
                    <h3 className="nav__text">{next.title}</h3>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Post;
